import React from "react";
import { Input, Button, Select, message } from "antd";

const ComLogin = () => {
  const onFinish = async (values) => {
    try {
      message.error("帳號密碼錯誤");
    } catch (error) {
      console.log(error);
      const { msg } = error;
      const newMsg = `${msg} 登入失敗`;
      message.error(newMsg);
    }
  };
  return (
    <div className="login">
      <div className="login__item">
        <h3 className="login__title">
         丁丽霞 - 登录
        </h3>
        <Select
          showSearch
          optionFilterProp="children"
          defaultValue={{
            value: 'Chinese',
            label: '中文',
          }}
          options={[
            {
              value: 'Chinese',
              label: '中文',
            },
            {
              value: 'English',
              label: 'English',
            },
          ]}
        />
      </div>
      <div className="login__item">
        <Input placeholder="請輸入帳號" />
      </div>
      <div className="login__item">
        <Input.Password placeholder="請輸入密碼" />
      </div>
      <div className="login__item">
        <Button className="login__button" type="primary" htmlType="submit">
          登录
        </Button>
      </div>
      <div className="login__item login__item--end">
        <p className="login__txt">浙ICP备18018015号-8号-2</p>
      </div>
    </div>
  );
};

export default ComLogin;
