import React from "react";
import ComLogin from "@/components/ComLogin";
import { Layout } from "antd";
const { Content } = Layout;

const Login = () => {
  return (
    <Layout>
      <Content className="singleContent">
        <div className="singleContent__item">
          <ComLogin />
        </div>
      </Content>
    </Layout>
  );
};
export default Login;
