import React from "react";
import Login from "@/containers/Login";

const App = () => {
  return (
    <Login />
  );
};

export default App;
